import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"

const Label_settings = () => {
    let selectedPage = "label_settings";
    let userDetail = localStorage.getItem('ship_rocket_user');
    let userData = {};
    if (userDetail != null) {
        userData = JSON.parse(userDetail)
    }

    const [userSettingState, setUserSettingState] = useState({})
    const [stateSave, setStateSave] = useState({ upload_customer_logo: null })

    const [sampleProductDetail, setSampleProductDetail] = useState([{ name: "Mobile", qty: 1, amount: 10000 }, { name: "Laptop", qty: 2, amount: 20000 }, { name: "T-shirt", qty: 4, amount: 4000 }, { name: "Pant", qty: 3, amount: 45000 }, { name: "Watch", qty: 8, amount: 6300 }])
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };


    useEffect(() => {
        get_user_label_setting();

    }, [])


    const save_changes = () => {
        if (userData) {
            // let dataToSend = {
            //customer_id: userData.customer_id, label_setting: userSettingState
            // };

            // console.log("datatoSend", dataToSend)
            let url = config.apiUrl + '/user/save_shipment_label_setting';

            // console.log("headers => ", config.headers);
            const fd = new FormData();
            //fd.append("customer_id", userSettingState.cust_id);
            fd.append("hide_customer_mobile_number", userSettingState.hide_customer_mobile_number);
            fd.append("hide_grand_order_amount", userSettingState.hide_grand_order_amount);
            fd.append("hide_gst_number", userSettingState.hide_gst_number);
            fd.append("hide_pickup_address", userSettingState.hide_pickup_address);
            fd.append("hide_pickup_mobile_number", userSettingState.hide_pickup_mobile_number);
            fd.append("hide_product", userSettingState.hide_product);
            fd.append("hide_qty", userSettingState.hide_qty);
            fd.append("hide_rto_address", userSettingState.hide_rto_address);
            fd.append("hide_rto_mobile_number", userSettingState.hide_rto_mobile_number);
            fd.append("hide_sku", userSettingState.hide_sku);
            fd.append("hide_total_amount", userSettingState.hide_total_amount);
            fd.append("show_logo_on_label", userSettingState.show_logo_on_label);
            fd.append("show_number_of_line_items", userSettingState.show_number_of_line_items);
            fd.append("show_support_email_mobile", userSettingState.show_support_email_mobile);
            fd.append("support_email", userSettingState.support_email);
            fd.append("support_mobile", userSettingState.support_mobile);
            fd.append("trim_product_name_upto", userSettingState.trim_product_name_upto);
            fd.append("trim_sku_upto", userSettingState.trim_sku_upto);
            fd.append("use_channel_logo", userSettingState.use_channel_logo);
            fd.append("use_channel_logo", userSettingState.use_channel_logo);
            fd.append("label_size_print", userSettingState.label_size_print);



            console.log(stateSave.upload_customer_logo);
            if (stateSave.upload_customer_logo != null && stateSave.upload_customer_logo != '') {
                fd.append('upload_customer_logo', stateSave.upload_customer_logo[0])
            }

            axios.post(url, fd, { headers: config.headers })
                .then((res) => {
                    //   console.log("responseJson => ", res);
                    // setSummarystate(res.data.output)
                    if(res.data.status==true){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000
                          })
                          
                          Toast.fire({
                            background:"#206bc4",
                            type: 'success',
                            title: "Updated Successfully",
                            color:"white"
                          });
                        get_user_label_setting()
                    }
                  
                    // setUserSettingState(res.data.output)

                })
                .catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                      })
                      
                      Toast.fire({
                        background:"#206bc4",
                        type: 'unsuccess',
                        title: "Something Went Wrong",
                        color:"white"
                      });
                    //Hide Loader
                    //   setLoadingstate({...loadingstate,loading:false})
                    console.log(error);
                });
        }

    }

    const get_user_label_setting = () => {
        if (userData) {
            console.log("userDetail", userData)
            let dataToSend = {
                customer_id: userData.customer_id
            };

            console.log("datatoSend", dataToSend)
            let url = config.apiUrl + '/user/get_shipment_label_setting';

            // console.log("headers => ", config.headers);


            axios.post(url, dataToSend, { headers: config.headers })
                .then((res) => {
                    console.log("responseJson => ", res);
                    // setSummarystate(res.data.output)
                    setUserSettingState(res.data.output)

                })
                .catch((error) => {
                    //Hide Loader
                    //   setLoadingstate({...loadingstate,loading:false})
                    console.log(error);
                });
        }

    }

    const checkboxChange = (e) => {
        console.log(e.target.checked);
        setUserSettingState({ ...userSettingState, [e.target.name]: e.target.checked })

    }

    const handleInputChange = (e) => {
        setUserSettingState({ ...userSettingState, [e.target.name]: e.target.value })

    }

    const handleChange = (event) => {
        setStateSave({ upload_customer_logo: event.target.files })
    }

    const customerLogo = () => {
        console.log("uimge ", stateSave);
        console.log("userSettingState ", userSettingState);
        let viewImage = "/img/placeholder-image.png";
        if (userSettingState.customer_logo) {
            viewImage = "https://cdn.shipbees.in/images/" + userSettingState.customer_logo
        }
        if (stateSave.upload_customer_logo && stateSave.upload_customer_logo.length > 0) {
            viewImage = URL.createObjectURL(stateSave.upload_customer_logo[0])
        }
        console.log("viewImage ", viewImage);
        return viewImage
    }

    const calculateColSpan = () => {
        let colSpan = 1;
        if (!userSettingState.hide_sku) colSpan++;
        if (!userSettingState.hide_product) colSpan++;
        if (!userSettingState.hide_qty) colSpan++;
        if (!userSettingState.hide_total_amount) colSpan++;

        // Set colSpan to 1 if all elements are visible
        if (colSpan === 0) colSpan = 3;

        return colSpan;
    };

    return (
        <div>
            {/* <?php include 'meta.php';?> */}
            <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
                {/* <?php include 'header.php';?>  */}
                <Header />


                <section class="mb-3 mt-3 tools">
                    <div class="container">
                        <h3 class="pt-3">Label Settings</h3>
                        <nav aria-label="breadcrumb" class="pb-3">
                            <ol class="breadcrumb  ">
                                <li class="breadcrumb-item"><a href="./">Home</a></li>
                                <li class="breadcrumb-item " aria-current="page"><a href="settings.php">Settings</a></li>
                            </ol>
                        </nav>

                        <ul class="nav nav-pills">
                            <li class="nav-item">
                                <a class={`nav-link ${(selectedPage == "label_settings") ? "active" : ""}`} href="label_settings">Global Label Settings</a>
                            </li>
                            <li class="nav-item">
                                <a class={`nav-link ${(selectedPage == "label_size_settings") ? "active" : ""}`} href="label_size_settings">Label Size Settings</a>
                            </li>
                        </ul>
                        <div class="row">
                            <div class="col-12 col-md">
                                <div class="row mt-4 mb-3">
                                    <div class="col-12 col-md-3">
                                        <h5>Common Setting</h5>
                                    </div>
                                    <div class="col-12 col-md">
                                        <div class="card border-0 shadow-sm mb-3">
                                            <div class="card-body">
                                                <h6>Common Setting</h6>
                                                <div class="row py-3">
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.show_logo_on_label} onChange={(e) => checkboxChange(e)} name='show_logo_on_label' />
                                                            <label class="form-check-label" for="">Show Logo on Label</label>
                                                            <small class="text-muted">(Select the box to upload your brand’s logo to display on labels. Or, you may select “Use Channel Logo” to show on labels)</small>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <div class="" style={{ width: "100%" }}>
                                                                <div class="mb-3 mt-3">
                                                                    <label for="" class="form-label fs-12">Upload Custom Logo:</label>
                                                                    <input type="file" class="form-control form-control-sm" id="" placeholder="Select logo" name="image" onChange={(e) => handleChange(e)} />
                                                                </div>
                                                            </div>
                                                            <div class="ps-2 images_3">
                                                                <img src={customerLogo()} class="img-fluid" style={{ width: "150px" }} />
                                                            </div>
                                                        </div>
                                                        {/* <div class="mb-3 mt-3">
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.use_channel_logo} onChange={(e) => checkboxChange(e)} name='use_channel_logo' />
                                                                <label class="form-check-label" for="">Use Carrier Logo</label>
                                                            </div>
                                                        </div> */}
                                                        <hr />
                                                    </div>
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.show_support_email_mobile} onChange={(e) => checkboxChange(e)} name='show_support_email_mobile' />
                                                            <label class="form-check-label" for="">Show Support Email/Mobile No</label>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12 col-md">
                                                                <label for="" class="form-label fs-12">Enter Email Id:</label>
                                                                <input type="text" class="form-control form-control-sm" id="" placeholder="Enter email" name="support_email" value={userSettingState.support_email} onChange={(e) => handleInputChange(e)} />
                                                            </div>
                                                            <div class="col-12 col-md">
                                                                <label for="" class="form-label fs-12">Enter Mobile No:</label>
                                                                <input type="text" class="form-control form-control-sm" id="" placeholder="Enter Mobile No" name="support_mobile" value={userSettingState.support_mobile} onChange={(e) => handleInputChange(e)} />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_customer_mobile_number} onChange={(e) => checkboxChange(e)} name='hide_customer_mobile_number' />
                                                            <label class="form-check-label" for="">Hide Customer Mobile Number</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row mt-4 mb-3">
                                    <div class="col-12 col-md-3">
                                        <h5>Warehouse Setting</h5>
                                    </div>
                                    <div class="col-12 col-md">
                                        <div class="card border-0 shadow-sm mb-3">
                                            <div class="card-body">
                                                <h6>Warehouse Setting</h6>
                                                <div class="row py-3">
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_pickup_address} onChange={(e) => checkboxChange(e)} name='hide_pickup_address' />
                                                            <label class="form-check-label" for="">Hide Pickup Address</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_pickup_mobile_number} onChange={(e) => checkboxChange(e)} name='hide_pickup_mobile_number' />
                                                            <label class="form-check-label" for="">Hide Pickup Mobile Number</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_rto_address} onChange={(e) => checkboxChange(e)} name='hide_rto_address' />
                                                            <label class="form-check-label" for="">Hide RTO Address</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_rto_mobile_number} onChange={(e) => checkboxChange(e)} name='hide_rto_mobile_number' />
                                                            <label class="form-check-label" for="">Hide RTO Mobile Number</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_gst_number} onChange={(e) => checkboxChange(e)} name='hide_gst_number' />
                                                            <label class="form-check-label" for="">Hide GST Number</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="row mt-4 mb-3">
                                    <div class="col-12 col-md-3">
                                        <h5>Hide Product Details Setting</h5>
                                    </div>
                                    <div class="col-12 col-md">
                                        <div class="card border-0 shadow-sm mb-3">
                                            <div class="card-body">
                                                <h6>Hide Product Details Setting</h6>
                                                <div class="row py-3">
                                                    {/* <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_sku} onChange={(e) => checkboxChange(e)} name='hide_sku' />
                                                            <label class="form-check-label" for="">Hide SKU</label>
                                                        </div>
                                                    </div> */}
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_product} onChange={(e) => checkboxChange(e)} name='hide_product' />
                                                            <label class="form-check-label" for="">Hide Product</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_qty} onChange={(e) => checkboxChange(e)} name='hide_qty' />
                                                            <label class="form-check-label" for="">Hide QTY</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_total_amount} onChange={(e) => checkboxChange(e)} name='hide_total_amount' />
                                                            <label class="form-check-label" for="">Hide Total Amount</label>
                                                        </div>
                                                    </div>
                                                    {/* <div class="col-12 col-md-12 mb-3">
                                                        <div class="form-check form-switch  mb-2">
                                                            <input class="form-check-input" type="checkbox" role="switch" id="" checked={userSettingState.hide_grand_order_amount} onChange={(e) => checkboxChange(e)} name='hide_grand_order_amount' />
                                                            <label class="form-check-label" for="">Hide Grand Order Amount</label>
                                                        </div>
                                                    </div> */}
                                                    {/* <div class="col-12 col-md-12 mb-3">
                                                        <hr />
                                                        <label for="email" class="form-label">Trim SKU Upto</label>
                                                        <input type="text" class="form-control" id="email" placeholder="Trim SKU Upto" autocomplete="new-username" name="trim_sku_upto" value={userSettingState.trim_sku_upto} onChange={(e) => handleInputChange(e)} />
                                                    </div> */}
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <label for="email" class="form-label">Trim Product Name Upto</label>
                                                        <input type="text" class="form-control" id="email" placeholder="Trim Product Name Upto" autocomplete="new-username" name="trim_product_name_upto" value={userSettingState.trim_product_name_upto} onChange={(e) => handleInputChange(e)} />
                                                    </div>
                                                    <div class="col-12 col-md-12 mb-3">
                                                        <label for="email" class="form-label">Show Number of Line Items</label>
                                                        <input type="text" class="form-control" id="email" placeholder="Show Number of Line Items" autocomplete="new-username" name="show_number_of_line_items" value={userSettingState.show_number_of_line_items} onChange={(e) => handleInputChange(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row mt-4 mb-3">
                                    <div class="col-12 col-md-3">
                                    </div>
                                    <div class="col-12 col-md">
                                        <div class="pb-4">
                                            <Button class="btn btn-primary" onClick={() => save_changes()} type="button">Save Changes</Button>
                                            {/* <a href="#" class="btn btn-primary">Save Changes</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-5">
                                <section class="mb-3 sticky-top">
                                    <div class="card border-dark rounded-0 lable_layout_1">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12 col-md-9">
                                                    <h2>To:</h2>
                                                    <h3>Mr. Kishan</h3>
                                                    <h3>62, Test Patel Apartments, Sector-512, Gurugram, Haryana, India</h3>
                                                    {(userSettingState.hide_customer_mobile_number) ? <h3>Mobile No: 9999999999</h3> : ""}
                                                    <h3>131028</h3>
                                                </div>
                                                {(userSettingState.show_logo_on_label && userSettingState.customer_logo ) ?
                                                    <div class="col-12 col-md">
                                                        <img src={ "https://cdn.shipbees.in/images/" + userSettingState.customer_logo } class="img-fluid logo" />

                                                    </div>
                                                    
                                                    
                                                  
                                               
                                          
                                           :""
                                                }
                                            </div>
                                            <hr />
                                            <div class="row mb-3">
                                                <div class="col-12 col-md">
                                                    <h3>Order Date: <strong>Jul 27,2023</strong></h3>
                                                    <h3>Invoice No: <strong>1686558180</strong></h3>
                                                </div>
                                                <div class="col-12 col-md text-center svg-container">
                                                <img src= "https://cdn.shiport.in/images/ecom.svg" class="img-fluid logo" />
                                                    {/* <img src={"/img/barcode.png"} class="img-fluid barcode" /> */}
                                                    {/* <svg height="70px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 15.273 15.273" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path style="fill:#030104;" d="M0,1.803h0.897V13.47H0V1.803z M1.446,13.47h0.505V1.803H1.446V13.47z M2.315,13.47h0.504V1.803 H2.315V13.47z M3.482,13.47h1.104V1.803H3.482V13.47z M5.47,13.47h0.552V1.803H5.47V13.47z M6.878,13.47h2.288V1.803H6.878V13.47z M12.84,13.47h0.752V1.803H12.84V13.47z M14.248,1.803V13.47h1.025V1.803H14.248z M10.084,13.47h0.492V1.803h-0.492V13.47z M11.281,13.47h0.492V1.803h-0.492V13.47z"></path> </g> </g></svg> */}
                                                    {/* <h6>1686558180</h6> */}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-12 text-center svg-container'>
                                                    <img src={"/img/barcode.png"} class="img-fluid barcode" style={{height:"70px" , width:"250px"}} />
                                                    <h6 style={{fontSize:"25px"}}>1686558180</h6>

                                                </div>
                                            </div>
                                            <hr />
                                            <div class="row mb-3">
                                                <div class="col-12 col-md text-center">
                                                    <h5>COD</h5>
                                                    <h5>₹100</h5>
                                                    <small>WEIGHT: 0.5KG</small>
                                                </div>
                                                <div class="col-12 col-md text-center">
                                                    <h6>Smarter</h6>
                                                    {/* <img src="./images/barcode.png" class="img-fluid barcode" />  */}
                                                    <h6>XSE21157413001</h6>
                                                    <small>Dimensions (cm): 10X 10X 10</small>
                                                </div>
                                            </div>

                                            <div class="table-responsive mb-4">
                                                <table class="table table-bordered border-dark">
                                                    <thead>
                                                        <tr>
                                                            {/* {userSettingState.hide_sku ? <th>SKU</th> : ""} */}
                                                            {userSettingState.hide_product ? <th>Item Name</th> : ""}
                                                            {userSettingState.hide_qty ? <th>Qty.</th> : ""}
                                                            {userSettingState.hide_total_amount ? <th>Amount</th> : ""}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sampleProductDetail.map((ele, index) =>
                                                            (index < userSettingState.show_number_of_line_items) ?
                                                                <tr>
                                                                    {/* {userSettingState.hide_sku ? <td>MOB-M-202-LAV</td> : ""} */}
                                                                    {userSettingState.hide_product ? <td>{ele.name.substring(0, userSettingState.trim_product_name_upto)}</td> : ""}
                                                                    {userSettingState.hide_qty ? <td>{ele.qty}</td> : ""}
                                                                    {userSettingState.hide_total_amount ? <td>&#8377;{ele.amount}</td> : ""}
                                                                </tr>
                                                                : ""

                                                        )}

                                                    </tbody>
                                                </table>
                                            </div>
                                            {!userSettingState.hide_pickup_address && !userSettingState.hide_pickup_mobile_number?"":
                                            <div class="mb-4">
                                                <h2>Pickup Address:</h2>
                                                {userSettingState.hide_pickup_address ? <h4>Pickup Warehouse Name</h4> : ""}
                                                {userSettingState.hide_pickup_address ? <h3>Test 234 45678 gurugram, Haryana, India - 122001</h3> : ""}
                                                <h3>{userSettingState.hide_pickup_mobile_number ? "Mobile No: 9999999999 ," : ""} {userSettingState.hide_gst_number ? "GST No: 123456789876543" : ""}</h3>
                                            </div>
}
                                            {!userSettingState.hide_rto_address && !userSettingState.hide_rto_mobile_number?"":
                                            <div class="mb-4">
                                                <h2>Return Address:</h2>
                                                {userSettingState.hide_rto_address ? <h4>Return Warehouse Name</h4> : ""}
                                                {userSettingState.hide_rto_address ? <h3>Test 234 45678 gurugram, Haryana, India - 122001</h3> : ""}
                                                <h3>{userSettingState.hide_rto_mobile_number ? "Mobile No: 9999999999," : ""}  {userSettingState.hide_gst_number ? "GST No: 123456789876543" : ""}</h3>
                                            </div>
                                             }
                                            {!userSettingState.show_support_email_mobile ?"":
                                            <div class="mb-3">
                                                <h2>For any query please contact:</h2>
                                                <h3>{userSettingState.show_support_email_mobile ? `Mobile: ${userSettingState.support_mobile ? userSettingState.support_mobile : ""}, Email: ${userSettingState.support_email ? userSettingState.support_email : ""}` : ""}</h3>
                                            </div>}

                                            <hr />
                                            <small>This is computer generated document, hence does not required signature.</small>
                                            <div><strong>Note:</strong> <small>All disputes are subject to Delhi jurisdiction. Goods once sold will only be taken back or exchanged as per the store's exchange/return policy</small></div>



                                        </div>
                                    </div>

                                </section>

                            </div>


                            {/* <?php include 'footer.php';?> */}
                        </div>
                    </div>
                </section>
          </div>
        </div>
    )
}

export default Label_settings
